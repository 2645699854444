/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from 'lodash.debounce';

import {Link, useLocation} from "react-router-dom";

import * as Actions from "actions";
import {
  isShopSiteAndSubdomain,
  isMarketPlace,
  checkProductInCart,
  getLogo,
  isFeatureSupported,
  getURI, getPrice
} from "helpers/utils";

import {
  TextField, InputAdornment, IconButton, Container, Typography, Chip, Box, Grid,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ShopProductItem from "components/elements/user/products/ShopProductItem";
import ShopProductDetail from "components/elements/user/products/ShopProductDetail";
import SearchIcon from "@mui/icons-material/Search";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { H6 } from "components/CustomTypography";
import {SearchOutlinedIcon} from "../UserCombinedSearch";
import StoriesPlayer from "components/StoriesPlayer/StoriesPlayer";
import MockProductBoxList from "components/elements/main/MockProductBoxList";
import ShopCategorySelector from "components/elements/user/products/ShopCategorySelector";
import CustomSectionCreator from "components/CustomSectionCreator";
import CustomFlexBox from "../../../CustomFlexBox";
import {Button} from "../../../Atoms";
import UserProductStatusSelector from "../../../elements/user/products/UserProductStatusSelector";
import ButtonComponent from "../../../Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactGA from "react-ga4";
import * as ActionTypes from "../../../../constants/ActionTypes";

const ShopProductSearch = (props) => {
  const [state, setState] = useState({
    limit: 20,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
    searchvalue: "Search products",
    isShop: true,
  });

  const [productState, setProductState] = useState("");
  const [productModeState, setProductModeState] = useState("PREVIEW");
  const [categoryState, setCategoryState] = useState({ selectedCategory: (props.categoryInput ? props.categoryInput : null) });
  const [optionValues, setOptionValues] = useState([]);

  const [sortState, setSortState] = useState("popular");
  const [offsetState, setOffsetState] = useState(0);

  const [loadStoryState, setLoadStoryState] = useState(false);
  const [loadIdState, setLoadIdState] = useState("");

  const {
    isLoading,  currency, addProductToCart, addProductToWishlist, addToCompare,
    userResetSearchProducts, userResetsearchKeywords,
    usersearchProducts, usersearchKeywords,
    usergetProductCategories,
    userGetProductInfo,
    bootupInfo, reqStatus, reqCartStatus, serviceCartError,
    products, productdetail, searchkeywords, match, history, symbol = "$",
    categoryList,
    cartItems, cartAddingPid, isCartLoading, cartList,
    shopId, isShop, limit,
    userDetails
  } = props;

  const { keywords } = searchkeywords;
  const {
    resetvalue,
  } = state;

  const { query } = match.params;
  const { sort } = match.params;

  const urlparams = useLocation();

  useEffect(() => {

    // Get a specific query parameter
    const checkStory = new URLSearchParams(urlparams.search).get('ls');

    if (checkStory) {
      setLoadStoryState(true);
    }

    const loadIndex = new URLSearchParams(urlparams.search).get('li');

    if (loadIndex) {
      setLoadIdState(loadIndex);
    }

    if (sort) {
      setSortState(sort);
    }

    // Do NOT reset previous results as Reels gets affected

    if (query) {
      usersearchProducts(query, shopId, isShop, isShopSiteAndSubdomain(), "", sort, 0);
    } else {
      usergetProductCategories(shopId, isShop, isShopSiteAndSubdomain());
      // load with default category
      usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sort, 0);
    }
  }, []);

  useEffect(() => {
    switch (reqCartStatus) {
      case ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS:
        usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sortState, offsetState);
        setProductModeState("PREVIEW");
        break;
      default:
        break;
    }
  }, [serviceCartError, reqCartStatus]);

  const runSearch = (keyword) => {
    userResetsearchKeywords();
    setState({ searchquery: keyword });
    setState({ resetvalue: "" });

    if (isShopSiteAndSubdomain()) {
      history.push(`/products/search/${keyword}`);
    } else {
      history.push(`/shop/${shopId}/products/search/${keyword}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      usersearchKeywords(value, isShopSiteAndSubdomain() ? bootupInfo.appState.subdomain_shopId : shopId, isShop, isShopSiteAndSubdomain(), limit);
    }
  };

  const debouncedChangeHandler = useCallback(
      debounce(runKeywordSearch, 300)
      , []);

  const selectKeywordSearch = (value) => {
    if (isShopSiteAndSubdomain()) {
      history.push(`/products/search/${value}`);
    } else {
      history.push(`/shop/${shopId}/products/search/${value}`);
    }
  };

  const handleDelete = () => {
    if (isShopSiteAndSubdomain()) {
      history.push("/products");
  } else {
      history.push(`/shop/${shopId}/products`);
    }
  };

  const handleAddProductToWishlist = (prodId) => {
    addProductToWishlist(prodId);
  };

  const handleProductView = (mode, product, index, withStory, reset) => () => {

    if (withStory) {
      setLoadIdState(index);
    }

    setProductModeState(mode);

    if (reset) {
      setProductState({
        selectedProduct: {
          _id: "",
          product_id: "",
          shop_id: "",
          status: "",
          images: [],
          video: [],
          title: "",
          logo: [],
          area: "",
        },
      });
    } else {

      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
          category: "User Interaction",
          action: "Shop Product Clicked : Story - " + withStory,
          label: product.title + " " + product.shop_id + " " + product.product_id, // Optional
        });
      }

      userGetProductInfo(product.product_id);
      setProductState({
        selectedProduct: {
          _id: product._id,
          product_id: product.product_id,
          shop_id: product.shop_id,
          status: product.status,
          images: product.images,
          video: product.video,
          title: product.title,
          logo: product.logo,
          area: product.area,
        },
      });
    }
  };

  const resetCategory = () => {
    setOffsetState(0);
    setCategoryState({ selectedCategory: null });
    usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sort, 0);
  };

  const setCategory = (category) => {
    setOffsetState(0);
    setCategoryState({ selectedCategory: category.id });
    usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), category.id, sort, 0);
  };

  const openStoriesView = () => {
    setProductModeState("STORIES");
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);


  const setProductStatus = (sort) => {
    setOffsetState(0);
    setSortState(sort);
    usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sort, 0);
  };

  const handleRestAll = () => {
    setOffsetState(0);
    setSortState("");
    setCategoryState("");
    usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sort, 0);
  };

  const handlePrevOffset = () => {
    const offset = offsetState > 1 ? offsetState - 1 : 0;
    setOffsetState(offset);
    usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sort, offset);
  };

  const handleNextOffset = () => {
    const offset = offsetState + 1;
    setOffsetState(offset);
    usersearchProducts("", shopId, isShop, isShopSiteAndSubdomain(), "", sort, offset);
  };

  const handleResetResultsStory = () => {
    setProductModeState("PREVIEW");
    setLoadIdState("");
  };

  const handleResetProductStory = () => {
    setLoadIdState("");
  };

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/products/search" || match.path !== "/shop/:shopId/products/search")) {
      history.push(isShopSiteAndSubdomain() ?  "/products/search" : `/shop/${shopId}/products/search`);
    }
  };

  let isModalSearch = false;

  const renderNoResults = () => (
      <Container>
        <Box>
          <Typography className="m-2" align="center">
            <H6>
              No product found
            </H6>
          </Typography>
        </Box>
      </Container>
  );

  var type = {
    'new': 'Latest Products',
    'popular': 'Popular Products',
    'default': 'Popular Products'
  };

  const renderResults = () => (
      <div>
        {isFeatureSupported("shopreels") &&
            <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              position: 'fixed',
              width: '100%',
              bottom: 70,
              justifyContent: 'center',
              zIndex: 'modal'
            }}>
              <Button
                  sx={{marginRight: '2px'}}
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={openStoriesView}
              >
                <SlideshowIcon /> Open {process.env.REACT_APP_REELS_TAG}
              </Button>
            </CustomFlexBox>
        }
      <Grid item container md={9} xs={12} spacing={0}>
        {products.map((product, index) => (
            <Grid item xs={6} sm={4} key={index}>
              <ShopProductItem
                  product={product}
                  symbol={currency}
                  inCart={checkProductInCart(cartItems, product.product_id)}
                  isCartLoading={isCartLoading}
                  cartAddingPid={cartAddingPid}
                  shopId={shopId}
                  onAddToWishlistClicked={() => handleAddProductToWishlist(product.product_id)}
                  onAddToCartClicked={() => addProductToCart(product.product_id, isMarketPlace() ? shopId : bootupInfo.appState.subdomain_shopId, !isMarketPlace())}
                  viewProductWithStory={handleProductView("STORIESVIEW", product, index, true, false)}
                  viewProduct={handleProductView("VIEW", product, index, false, false)}
                  key={`${index + 1}`}
              />
            </Grid>
        ))}
      </Grid>
      </div>
  );

  const storiesData = products.map((item) => {
    const {id, images, video, name } = item;

    let stories = [];

    if (video && video.length > 0) {
      stories.push({
        video: video[0].cdn.secure_url,
      });
    } else {
      images && images.map((product) => (
          stories.push({
            image: product.cdn.secure_url,
          })
      ));
    }


    return (
        {
          user: {
            avatar : "",
            name: "Buy: " + getPrice(item, currency),
            userLink: '/#' + getURI(process.env.REACT_APP_TYPE, "shop_product", shopId, id, ""),
            area: "",
          },
          stories: stories,
        }
    );
  });

  const renderResultsAsStories = () => (
      <div>
        <StoriesPlayer
            loadIndex={loadIdState}
            storiesData={storiesData}
            onCancel={() => handleResetResultsStory()}
        />
      </div>
  );


  const renderProduct = () => (

        <ShopProductDetail
            setState={(value, field) => setProductState({
              ...state,
              selectedProduct: {
                ...selectedProduct,
                [field]: value,
              },
            })}
            currency={currency}
            bootupInfo={bootupInfo}
            userDetails={userDetails}
            cartItems={cartItems}
            isLoading={isLoading}
            isCartLoading={isCartLoading}
            cartAddingPid={cartAddingPid}
            productdetail={productdetail}
            selectedProduct={selectedProduct}
            onAddToWishlistClicked={() => handleAddProductToWishlist(productdetail.product_id)}
            onAddToCartClicked={() => addProductToCart(productdetail.product_id, isMarketPlace() ? shopId : bootupInfo.appState.subdomain_shopId, !isMarketPlace())}
            history={history}
            onCancel={() => setProductModeState("PREVIEW")}
        />

  );

  const renderProductWithStoriesPlayer = (productData) => (

      <div>

        {renderProduct()}

        <StoriesPlayer
            storiesData={productData}
            onCancel={() => handleResetProductStory()}
        />
      </div>

  );

  const renderSearchHelp = () => (
      <Container>
        <Box>
          {
            window.innerWidth <= 500
            && (match.path === "/products/search" || match.path === "/shop/:shopId/products/search")
            && optionValues.map((element) => (
                <Container>
                  <Typography onClick={() => history.push(`search/${element.label}`)}>
                    <IconButton disabled size="large">
                      <SearchIcon />
                    </IconButton>
                    {element.label}
                  </Typography>
                </Container>
            ))
          }
        </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box mb={2}>
        {
            window.innerWidth <= 500
            && (match.path === "/products/search" || match.path === "/shop/:shopId/products/search" ) ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={handleKeyPress}
          onSelect={(input) => debouncedChangeHandler(input.target.value)}
          options={window.innerWidth <= 500 ? [] : optionValues.map((element) => element.label)}
          renderInput={(params) => (
            <TextField
                {...params}
              InputProps={{
                sx: {
                  height: 44,
                  paddingRight: 0,
                  borderRadius: 0,
                  color: "grey.900",
                  overflow: "hidden",
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
                startAdornment: <SearchOutlinedIcon fontSize="small" />,
              }}
              autoFocus={isModalSearch}
              placeholder={`Search ${process.env.REACT_APP_PRODUCT_TITLE}`}
            />
          )}
        />
        </Box>
      </Container>

      {
        renderSearchHelp()
          }

      <Container>
        {query && (
        <Chip
          label={query}
          onDelete={handleDelete}
        />
        )}
      </Container>

      { /* match.path !== "/product"
      && */ match.path !== "/"
      && (
          match.path === "/products" ||
          match.path === "/products/search/:query" ||
          match.path === "/products/sort/:sort" ||
          match.path === "/shop/:shopId/products" ||
          match.path === "/shop/:shopId/products/search/:query" ||
          match.path === "/shop/:shopId/products/sort/:sort"
      ) && (
      <>
        { !query &&
            <ShopCategorySelector
                title="All Product Categories"
                categoryList={categoryList}
                handlePage={handlePage}
                resetCategory={resetCategory}
                onCategorySelect={setCategory}
                selectedCategory={selectedCategory}
            />
        }
        {/* !query && (<CustomSectionCreator title={type[sort] || type['default']}  />) */}
        {query && (<CustomSectionCreator title={"Searching: '" + query + "'"} />)}

        <CustomFlexBox alignItems="center" mb={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box width="50%">
            <UserProductStatusSelector
                onStatusSelect={setProductStatus}
                productstatus={sortState}
            />
          </Box>
          <Box width="20%">
            <ButtonComponent
                type="primary"
                variant="text"
                name="Reset"
                size="medium"
                onClick={handleRestAll}>
            </ButtonComponent>
          </Box>
          <Box width="30%">
            {!(isLoading) && products.length > 0 && <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}>
              <Box width="50%" align="right">
                {offsetState > 0 && <IconButton onClick={handlePrevOffset}>
                  <ArrowBackIosIcon fontSize="medium" color="primary"/>
                </IconButton>
                }
              </Box>
              <Box width="50%" align="right">
                <IconButton onClick={handleNextOffset}>
                  <ArrowForwardIosIcon fontSize="medium" color="primary"/>
                </IconButton>

              </Box>
            </CustomFlexBox>
            }

          </Box>
        </CustomFlexBox>

        {isLoading ? <MockProductBoxList /> : products.length > 0 ? renderResults() : renderNoResults()}
        {!isLoading && products.length > 0 && loadStoryState === true ? (openStoriesView(), setLoadStoryState(false)) : ""}
      </>
      )}
    </>
  );

  const renderProductMode = (productMode) => {
    switch (productMode) {

      case "STORIES":
        return renderResultsAsStories();

      case "STORIESVIEW":

        const {id, shop_id, images, video, title } = selectedProduct;

        let productStoryData = [];
        let stories = [];

        if (video && video.length > 0) {
          stories.push({
            video: video[0].cdn.secure_url,
          });
        } else {
          images && images.map((product) => (
              stories.push({
                image: product.cdn.secure_url,
              })
          ));
        }


        productStoryData.push(
            {
              user: {
                avatar: "",
                name: title,
                userLink: "/#/shop/" + shop_id,
                area: "",
              },
              stories: stories,
            }
        );

        return renderProductWithStoriesPlayer(productStoryData);

      case "VIEW":
        return renderProduct();

      case "PREVIEW":
      default:
        return renderSearchModule();
    }
  };

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const { selectedProduct = {} } = productState;
  const { records } = products;
  const { categories } = categoryList;
  const { selectedCategory = {} } = categoryState;

  return (
      <Container>
        {renderProductMode(productModeState)}
      </Container>
  );

};

ShopProductSearch.defaultProps = {
  currency: "",
};

ShopProductSearch.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usersearchProducts: PropTypes.func.isRequired,
  usersearchKeywords: PropTypes.func.isRequired,
  userResetsearchKeywords: PropTypes.func.isRequired,
  userResetSearchProducts: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ShopProductSearchComponent = connect(
  ({ userproducts, cartList, bootupInfo }) => ({
    bootupInfo,
    userDetails: bootupInfo.userDetails,
    currency: bootupInfo.symbol,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.searchproducts,
    productdetail: userproducts.productdetail,
    cartItems: cartList.cart,
    isCartLoading: cartList.isLoading,
    cartAddingPid: cartList.cartAddingPid,
    reqCartStatus: cartList.reqStatus,
    serviceCartError: cartList.serviceError,
    searchkeywords: userproducts.searchkeywords,
    categoryList: userproducts.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductSearch);

export { ShopProductSearchComponent as default };
