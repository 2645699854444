/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, {useCallback, useEffect, useState} from "react";
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from 'lodash.debounce';

import * as Actions from "actions";

import {
  TextField, InputAdornment, IconButton, Container, Typography, Chip, Box, Grid,
} from "@mui/material";

import {
  Notification,
} from "components/Atoms";
import { Button } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import UserSellerShop from "components/elements/user/shops/UserSellerShop";
import UserSellerShopView from "components/elements/user/shops/UserSellerShopView";
import MockProductBox from "components/elements/main/MockProductBox";
import { H5, H6 } from "components/CustomTypography";
import {SearchOutlinedIcon} from "../UserCombinedSearch";
import CustomSectionCreator from "components/CustomSectionCreator";
import LazyImage from "components/LazyImage";
import {
  getCategoryBreadcrum,
  getImage,
  getLocationBreadcrum,
  isMarketPlaceOnly,
  isFeatureSupported, getPlatform
} from "helpers/utils";
import UserShopLocationSlider from "components/elements/user/UserShopLocationSlider";
import * as ActionTypes from "constants/ActionTypes";
import StoriesPlayer from "components/StoriesPlayer/StoriesPlayer";
import CustomFlexBox from "components/CustomFlexBox";
import UserShopStatusSelector from "components/elements/user/shops/UserShopStatusSelector";
import ButtonComponent from "components/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SlideshowIcon from "@mui/icons-material/Slideshow";

const UserShopSearch = (props) => {

  const [state, setState] = useState({
    limit: 5,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
  });
  const [shopState, setShopState] = useState("");
  const [shopModeState, setShopModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);
  const [locationState, setLocationState] = useState("");
  const [marketState, setMarketState] = useState("");
  const [sortState, setSortState] = useState("popular");
  const [offsetState, setOffsetState] = useState(0);

  const [loadStoryState, setLoadStoryState] = useState(false);
  const [loadIdState, setLoadIdState] = useState("");

  const {
    serviceError, reqStatus, isLoading,
    shops, shopdetail, currency,
    userMainSearchShops, userMainGetShopInfoById,
    userMainSearchShopKeywords, citylocations,
    userMainResetSearchShops, userMainResetSearchShopKeywords,
    userMainAddStoreToFavlist,
    searchkeywords, match, history, symbol = "$", marketId
  } = props;

  const { keywords } = searchkeywords;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;
  const { sort } = match.params;
  const { location } = match.params;
  const { market } = match.params;
  const urlparams = useLocation();

  useEffect(() => {

    ReactGA.send({
      hitType: "pageview",
      page: "/shops",
      title: "Main Shops",
      custom: {
        platform: getPlatform(), // Add a custom property to distinguish platforms
      },
    });

    // Get a specific query parameter
    const checkStory = new URLSearchParams(urlparams.search).get('ls');

    if (checkStory) {
      setLoadStoryState(true);
    }

    const loadIndex = new URLSearchParams(urlparams.search).get('li');

    if (loadIndex) {
      setLoadIdState(loadIndex);
    }

    if (sort) {
       setSortState(sort);
    }

    if (query) {
      userMainResetSearchShops();
      userMainSearchShops(query, sort, "", marketId, offsetState);
    } else if (location) {
      // need lat/lng to do query
      setLocationState(location);
      userMainSearchShops("", sort, location, marketId, offsetState);
    } else if (market) {
      setMarketState(market);
      userMainSearchShops("", sort, market, marketId, offsetState);
    } else {
      userMainSearchShops("", sort, "", marketId, offsetState);
    }
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const runSearch = (keyword) => {
    userMainResetSearchShopKeywords();
    setState({ resetvalue: "" });
    history.push(`/shops/search/${keyword}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const selectItem = (e, value) => {
    if (e.key === "Enter") {
      runSearch(value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      history.push(`/shop/${option.key}`)
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      userMainSearchShopKeywords(value,
        props.bootupInfo.appState.subdomain_shopId, true, props.bootupInfo.appState.isWebsite);
    }
  };

  const debouncedChangeHandler = useCallback(
      debounce(runKeywordSearch, 300)
      , []);

  const handleDelete = () => {
    history.push("/shops");
  };


  const addShopFavourite = (shopId) => {
    userMainAddStoreToFavlist(shopId)
    setShopModeState("PREVIEW");
  };

  const handleShopEdit = (mode, shop, index, withStory, reset) => () => {

    if (withStory) {
      setLoadIdState(index);
    }

    setShopModeState(mode);

    if (reset) {
      setShopState({
        selectedShop: {
          shop_id: "",
          id: "",
          title: "",
          logo: [],
          video: [],
          images: [],
          topproducts: [],
          area: "",
        },
      });
    } else {

      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
          category: "User Interaction",
          action: "Main Shop Clicked : Story - " + withStory,
          label: shop.title + " " + shop.id// Optional
        });
      }

      setShopState({
        selectedShop: {
          shop_id: shop.id,
          id: shop.id,
          title: shop.title,
          logo: shop.logo,
          video: shop.video,
          images: shop.images,
          topproducts: shop.topproducts,
          area: shop.area,
        },
      });
    }
  };

  const openStoriesView = () => {
    setShopModeState("STORIES");
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);


  const handleLocationSelect = (loc) => {
    setLocationState(loc.id);
    setOffsetState(0);
    userMainSearchShops("", sortState, loc.id, marketId, 0);
  };

  const setShopStatus = (status) => {
    setSortState(status);
    setOffsetState(0);
    userMainSearchShops(query, status, locationState, marketId, 0);
  };

  const handleRestAll = () => {
    setOffsetState(0);
    setSortState("");
    setLocationState("");
    userMainSearchShops(query, "", "", marketId, 0);
  };

  const handlePrevOffset = () => {
    const offset = offsetState > 1 ? offsetState - 1 : 0;
    setOffsetState(offset);
    userMainSearchShops(query, sortState, locationState, marketId, offset);
  };

  const handleNextOffset = () => {
    const offset = offsetState + 1;
    setOffsetState(offset);
    userMainSearchShops(query, sortState, locationState, marketId, offset);
  };

  const handleResetResultsStory = () => {
    setShopModeState("PREVIEW");
    setLoadIdState("");
  };

  const handleResetShopStory = () => {
      setLoadIdState("");
  };

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/shops/search")) {
      props.history.push("/shops/search");
    }
  };

  let isModalSearch = false;

  const renderNoResults = () => (
      <Container>
        <Box>
          {query && (<Chip label={query} onDelete={handleDelete}/>)}
          {!query && (<CustomSectionCreator title="Popular Shops" />)}
          <Typography variant="h6">
              No shop found
          </Typography>
        </Box>
      </Container>
  );

  var type = {
    'new': 'New ' + process.env.REACT_APP_SHOP_TITLE,
    'popular': 'Popular ' + process.env.REACT_APP_SHOP_TITLE,
    'default': 'Popular ' + process.env.REACT_APP_SHOP_TITLE
  };

  const renderResults = () => (
      <div>
        {isFeatureSupported("shopreels") &&
            <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              position: 'fixed',
              width: '100%',
              bottom: 70,
              justifyContent: 'center',
              zIndex: 'modal'
            }}>
              <Button
                  sx={{marginRight: '2px'}}
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={openStoriesView}
              >
                <SlideshowIcon /> &nbsp; View as {process.env.REACT_APP_REELS_TAG}
              </Button>
            </CustomFlexBox>
        }
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
          {shops.map((shop, index) => (
              <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                <UserSellerShop
                    shop={shop}
                    symbol={currency}
                    viewShopWithStory={handleShopEdit("STORIESVIEW", shop, index, true, false)}
                    viewShop={handleShopEdit("VIEW", shop, index, false, false)}
                    key={`${index + 1}`}
                />
              </Grid>
          ))}
        </Grid>
      </div>
  );

  // stories data

  const storiesData = shops.map((item) => {
    const {id, title, logo, video, topproducts, area} = item;

    let stories = [];

    if (video && video.length > 0) {
      stories.push({
        video: video[0].cdn.secure_url,
      });
    } else {
      topproducts && topproducts.map((product) => (
          stories.push({
            image: product.cdn.secure_url,
          })
      ));
    }

    if (stories.length < 1) {
      stories.push({
        image: logo && logo[0].cdn.secure_url,
      });
    }

    return (
        {
          user: {
            avatar: logo[0].cdn.secure_url,
            name: title,
            userLink: "/#/shop/" + id,
            area: area,
          },
          stories: stories,
        }
    );
  });

  const renderResultsAsStories = () => (
      <div>
        <StoriesPlayer
            loadIndex={loadIdState}
            storiesData={storiesData}
            onCancel={() => handleResetResultsStory()}
        />
      </div>
  );

  const renderShopAsStories = (shopData) => (

      <div>
        <UserSellerShopView
            setState={(value, field) => setShopState({
              ...state,
              selectedShop: {
                ...selectedShop,
                [field]: value,
              },
            })}
            symbol={symbol}
            history={history}
            selectedShop={selectedShop}
            onAddToFavlistClicked={addShopFavourite}
            onCancel={() => setShopModeState("PREVIEW")}
        />
        <StoriesPlayer
            storiesData={shopData}
            onCancel={() => handleResetShopStory()}
        />
      </div>

  );

  const renderSearchHelpMobile = () => (
      <Container>
        <Box>
          {
            window.innerWidth <= 500
            && (match.path === "/shops/search")
            && optionValues.map((element) => (
                  <Box m={2} alignItems="center" width="100%" display="flex" onClick={() => history.push(`/shop/${element.value}`)}>
                    <Box width="75%">
                      <H5 fontSize={16} ellipsis>
                        {element.label}
                      </H5>
                      <H6 fontSize={12} ellipsis>
                        {element.sublabel}
                      </H6>
                    </Box>
                    <Box width="25%">
                      <LazyImage
                          src={getImage(element.images)}
                          width={60}
                          height={60}
                          mx="auto"
                      />
                    </Box>
                  </Box>
            ))
          }
        </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box mb={2}>
        {
            window.innerWidth <= 500
            && (match.path === "/shops/search") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete1"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={
            handleKeyPress
          }
          onChange={
            (event, newValue) => selectItem(event, newValue)

          }
          onSelect={
            (input) => debouncedChangeHandler(input.target.value)
          }
          options={
            window.innerWidth <= 500 ? [] : optionValues
          }
          renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
          )}
          renderInput={(params) => (
            <TextField
                {...params}
              InputProps={{
                sx: {
                  height: 44,
                  paddingRight: 0,
                  borderRadius: 0,
                  color: "grey.900",
                  overflow: "hidden",
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
                startAdornment: <SearchOutlinedIcon fontSize="small" />,
              }}
              autoFocus={isModalSearch}
              placeholder={`Search ${process.env.REACT_APP_SHOP_TITLE}`}
            />
          )}
        />
        </Box>
      </Container>

      {
        renderSearchHelpMobile()
      }

      <Container>
      { match.path !== "/"
      && (match.path === "/shops/search/:query" ||
              match.path === "/shops/search/:query" ||
              match.path === "/shops" ||
              match.path === "/shops/sort/:sort" ||
              match.path === "/shops/location/:location" ||
              match.path === "/shops/market/:market") && (
<>
  {isMarketPlaceOnly() && sortState !== "nearby" &&
      <UserShopLocationSlider
          {...props}
          isDirect={false}
          isProduct={false}
          setLocation={locationState}
          headerTitle={"By Location: " + (type[sortState] || type['default'])}
          handleSelectCategoryLink={handleLocationSelect}
          handleSelectCategorySet={handleLocationSelect}
      />
  }

  {query && (<CustomSectionCreator title={"Searching: '" + query + "'"} />)}
  {/* !query && (<CustomSectionCreator title={(type[sortState] || type['default']) + getLocationBreadcrum(citylocations, locationState)} />) */}



  <CustomFlexBox alignItems="center" mb={2} sx={{
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  }}>
    <Box width="50%">
      <UserShopStatusSelector
          onStatusSelect={setShopStatus}
          shopstatus={sortState}
      />
    </Box>
    <Box width="15%">
      <ButtonComponent
          type="primary"
          variant="outlined"
          name="All"
          size="medium"
          onClick={handleRestAll}>
      </ButtonComponent>
    </Box>
    <Box width="30%">
      {!(isLoading) && shops.length > 0 && <CustomFlexBox sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}>
        <Box width="50%" align="right">
          {offsetState > 0 &&  <Button
              size="medium"
              color="primary"
              variant="outlined"
              onClick={handlePrevOffset}
          >
            <ArrowBackIosIcon fontSize="small" color="primary"/>
          </Button>
          }
        </Box>
        <Box width="50%" align="right">
          <Button
              size="medium"
              color="primary"
              variant="outlined"
              onClick={handleNextOffset}
          >
            <ArrowForwardIosIcon fontSize="small" color="primary" />
          </Button>
        </Box>
      </CustomFlexBox>
      }
    </Box>
  </CustomFlexBox>



      {isLoading ? <MockProductBox />
            : shops.length > 0
              ? renderResults()
              : renderNoResults()}
      {!isLoading && shops.length > 0 && loadStoryState === true ? (openStoriesView(), setLoadStoryState(false)) : ""}
       </>
      )}
      </Container>
    </>
  );

  const renderShopMode = (shopMode) => {
    switch (shopMode) {

      case "STORIES":
        return renderResultsAsStories();

      case "PREVIEW":
        return renderSearchModule();

      case "STORIESVIEW":

        const {id, title, logo, video, topproducts, area} = selectedShop;

        let shopStoryData = [];
        let stories = [];

        if (video && video.length > 0) {
          stories.push({
            video: video[0].cdn.secure_url,
          });
        } else {
          topproducts && topproducts.map((product) => (
              stories.push({
                image: product.cdn.secure_url,
              })
          ));
        }

        if (stories.length < 1) {
          stories.push({
            image: logo && logo[0].cdn.secure_url,
          });
        }

        shopStoryData.push(
            {
              user: {
                avatar: logo[0].cdn.secure_url,
                name: title,
                userLink: "/#/shop/" + id,
                area: area,
              },
              stories: stories,
            }
        );

        return renderShopAsStories(shopStoryData);

      case "VIEW":
      default:
        return (
            <UserSellerShopView
                setState={(value, field) => setShopState({
                  ...state,
                  selectedShop: {
                    ...selectedShop,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                history={history}
                selectedShop={selectedShop}
                onAddToFavlistClicked={addShopFavourite}
                onCancel={() => setShopModeState("PREVIEW")}
            />
        );
    }
  };

  const { selectedShop = {} } = shopState;

  return (
      <Container>
        { renderShopMode(shopModeState)}
      </Container>
  );
};

UserShopSearch.defaultProps = {
  currency: "",
};

UserShopSearch.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  citylocations: PropTypes.objectOf(PropTypes.any).isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  userMainGetShopInfoById: PropTypes.func.isRequired,
  userMainSearchShops: PropTypes.func.isRequired,
  userMainSearchShopKeywords: PropTypes.func.isRequired,
  userMainResetSearchShopKeywords: PropTypes.func.isRequired,
  userMainResetSearchShops: PropTypes.func.isRequired,
  userMainAddStoreToFavlist: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userShopSearch = connect(
  ({ userMainShops, bootupInfo }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    reqStatus: userMainShops.reqStatus,
    isLoading: userMainShops.isLoading,
    serviceError: userMainShops.serviceError,
    citylocations: bootupInfo.citylocations,
    shops: userMainShops.searchshops,
    searchkeywords: userMainShops.searchkeywords,
    shopdetail: userMainShops.shopDetail,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserShopSearch);

export { userShopSearch as default };
