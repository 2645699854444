import * as React from "react";
import {
    Box, IconButton
} from "@mui/material";

import  { Edit, MoreHoriz } from '@mui/icons-material';
import {getImage, getPrice} from "helpers/utils";
import "./SellerProduct.scss";
import BazaarButton from "components/BazaarButton";
import { FlexBetween, FlexBox } from "components/flex-box";
import {H2, H5} from "components/CustomTypography";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import ArrowRight from "@mui/icons-material/ArrowRight";


const SellerProduct = (props) => {
  const { product = {}, editShopImages, editProductInfo, editProduct, symbol, currency, className = "" } = props;
  const { id, name, categories, images, price, is_popular, is_price_visible, is_price_onunlock, is_active, store_entry } = product;
  const galleryInput = images.map((image) => {
    const { id, cdn } = image;
    return {
      id,
      original: cdn.secure_url,
      thumbnail: cdn.secure_url,
    };
  });

  return (
      <Box pb={3} >
          <HoverBox onClick={editProduct} m={0} mx="auto" borderRadius="2px">
              <LazyImage
                  src={getImage(product.images)}
                  width="100%"
                  alt={product.name}
                  mx="auto"
              />
          </HoverBox>
          <FlexBetween onClick={editProduct} mt={0}>
              <Box width="10%">
                  <ArrowRight fontSize="small" color="primary" />
              </Box>
              <Box width="90%">
                  <H2 fontSize={14} textAlign="left" ellipsis>
                      {getPrice(product, currency)}
                  </H2>
                  <H5 fontSize={12} textAlign="left" ellipsis>
                      {product.name}
                  </H5>
              </Box>
          </FlexBetween>
      </Box>
  );
};

export default SellerProduct;
