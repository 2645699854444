// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";
import querystring from "querystring";
import {
  getGeoLocationDue,
  getGeoLocationLatLon,
  getGeoLocation,
  updateUserLocation,
  isLocationInSupportedCity, updateLastCheckTime, checkIfCityChanging
} from "helpers/utils";
import {getCityLocations} from "../../actions";

function* userMainSearchOffers(action: Object) {

  let coordinates;

    try {
      if (navigator.geolocation) {
        if (getGeoLocationDue()) {
          const usergetUserGeoLocation = () =>
              new Promise((resolve, reject) => {
                var options = {
                  timeout: 5000,
                  maximumAge: 0,
                  enableHighAccuracy: true
                };
                navigator.geolocation.getCurrentPosition(
                    (location) => resolve(location),
                    (error) => reject(error),
                    options
                );
              });

          const response = yield call(usergetUserGeoLocation);
          const {latitude, longitude} = response.coords;

          var currentCity = isLocationInSupportedCity(response.coords);

          if (currentCity) {
            if (checkIfCityChanging(currentCity)) {
              yield put(Actions.getCityLocations(currentCity.id));
            }
            updateUserLocation(response.coords, currentCity);
            coordinates = {lat: latitude, lng: longitude};
          } else {
            updateLastCheckTime();
            coordinates = getGeoLocation();
          }
        } else {
          coordinates = getGeoLocation();
        }
      }
    } catch
        (error) {
      // Reset time if running into error - check again after some time
      updateLastCheckTime();
      // get earlier or default location
      coordinates = getGeoLocation();
    }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: action.sort ? action.sort : "",
      location: action.location ? action.location : "",
      market: action.market ? action.market : "",
      name: action.payload
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/offers/get-offers` + query
    );
    yield put(Actions.userMainSearchOffersSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchOffersFailure(error));
  }
}

function* userMainGetNearbyOffers(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {
        coordinates = getGeoLocation();
      }
    }
  } catch (error) {
    // try if there is an earlier location
    coordinates = getGeoLocation();
//    coordinates =  {lat: "", lng: ""};
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      location: "nearby"
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;


    const response = yield API.get(
        `/users/offers/get-offers`  + query
    );
    yield put(Actions.userMainGetNearbyOffersSuccess(response.response));
  } catch (error) {
    yield put(Actions.userMainGetNearbyOffersFailure(error));
  }
}

function* userMainGetFavNearbyOffers(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
              yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {
        coordinates = getGeoLocation();
      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: "favs",
      location: "nearby"
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;


    const response = yield API.get(
        `/users/offers/get-offers`  + query
    );
    yield put(Actions.userMainGetFavNearbyOffersSuccess(response.response));
  } catch (error) {
    yield put(Actions.userMainGetFavNearbyOffersFailure(error));
  }
}

function* userMainGetLatestOffers(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
              yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {
        coordinates = getGeoLocation();
      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: "new",
      market: action.market ? action.market : ""
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/offers/get-offers`  + query
    );
    yield put(Actions.userMainGetLatestOffersSuccess(response.response));
  } catch (error) {
    yield put(Actions.userMainGetLatestOffersFailure(error));
  }

}

function* userMainGetOfferDetail(action: Object) {
  try {
    const response = yield API.get(
        `/users/offers/get-offer/` + action.payload
    );
    yield put(Actions.userMainGetOfferDetailSuccess(response.response));
  } catch (error) {
    yield put(Actions.userMainGetOfferDetailFailure(error));
  }
}


export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_OFFERS_START,             userMainSearchOffers);
  yield takeLatest(ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_START,         userMainGetNearbyOffers);
  yield takeLatest(ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_START,     userMainGetFavNearbyOffers);
  yield takeLatest(ActionTypes.USER_MAIN_GET_LATEST_OFFERS_START,         userMainGetLatestOffers);
  yield takeLatest(ActionTypes.USER_MAIN_GET_OFFER_DETAIL_START,          userMainGetOfferDetail);
}
