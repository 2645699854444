import * as React from "react";
import {
    Box, Card, Chip, Container, IconButton, InputAdornment, TextField, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import TextFieldComponent from "components/TextField";
import {
    getButtonDataApproved, getButtonDataCancel, getTextfieldDataMessage, getButtonDataSendMessage,
    schema, getButtonDataActivated, getButtonDataDisabled, getButtonDataDeleted
} from "./helper";
import { getLocalDate, checkUserAllowed } from "helpers/utils";

import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {useState} from "react";
import AdminProductVideoEditor from "components/pages/admin/products/AdminProductVideoEditor";


const AdminSellerProductEditor = ({
   selectedProduct, symbol, updateProductStatusClicked, adminUpdateProductVideoClicked, messageShopClicked, onCancel, productdetail, isLoading, userDetails,
}) => {

    const [state, setState] = useState({});

    let isModalSearch = false;


    const {
     created_at: createdAt, _id: _id, status, message, video
  } = selectedProduct || {};

  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinishApproved = () => {
      updateProductStatusClicked({ pid: productdetail.id, is_verified: true });
  };

    const onFinishDisabled = () => {
        updateProductStatusClicked({ pid: productdetail.id, is_verified: false });
    };

    const onFinishDeleted = () => {
        updateProductStatusClicked({ pid: productdetail.id, is_deleted: true });
    };

    const onFinishMessage = () => {
        messageShopClicked(productdetail, message);
    };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedProduct);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedProduct);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

    const renderProductInformation = () => (
        <Box p={2} width="100%" display="flex">
            <Box width="50%">
                <Typography className="styled-link" variant="subtitle2">
                    {` Id: ${_id}`}
                </Typography>
            </Box>
            <Box width="50%">
                <Typography className="styled-link" align="right" display="block" variant="subtitle2">
                    Date:
                    {" "}
                    {getLocalDate(createdAt)}
                </Typography>
            </Box>
        </Box>
    );


    return (
        <Container>
            <Container maxWidth="md">
                <Container className="header-color">
                    {renderProductInformation()}

                    <AdminProductVideoEditor
                        video={video}
                        prod_id={_id}
                        cancelEdit={onCancel}
                        adminUpdateProductVideoClicked={adminUpdateProductVideoClicked}
                    />

                </Container>
                <Box mb={2}>
                    {
                        getButtonDataCancel(onCancel).map((element) => (
                            <ButtonComponent
                                sx={{
                                    m: 2,
                                }}
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        ))
                    }
                </Box>
                <Box mb={2}>
                    {!isLoading && checkUserAllowed(userDetails.userlevel, "approveproduct") && productdetail.is_verified  !== true ?
                        getButtonDataActivated(onFinishApproved).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        )) : <div />
                    }
                </Box>
                <Box mb={2}>
                    {!isLoading && checkUserAllowed(userDetails.userlevel, "disableproduct") && productdetail.is_verified  === true ?
                        getButtonDataDisabled(onFinishDisabled).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        )) : <div />
                    }
                </Box>
                <Box mb={2}>
                    {!isLoading && checkUserAllowed(userDetails.userlevel, "deleteproduct") && productdetail.is_deleted  === false ?
                        getButtonDataDeleted(onFinishDeleted).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        )) : <div />
                    }
                </Box>
                <Box mb={2}>
                    {!isLoading && checkUserAllowed(userDetails.userlevel, "sendproductmsg") && productdetail.is_verified  !== true ?
                        getTextfieldDataMessage(status).map((element) => (
                            <Box className="textfield" m={2}>
                                <TextFieldComponent
                                    required
                                    label={element.label}
                                    value={element.value}
                                    error={!!getError(element.onChangeField)}
                                    helperText={getError(element.onChangeField)}
                                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                                />
                            </Box>
                        )) : <div />
                    }
                    {!isLoading && checkUserAllowed(userDetails.userlevel, "sendproductmsg") && productdetail.is_verified  !== true ?
                        getButtonDataSendMessage(onFinishMessage).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        )) : <div />
                    }
                </Box>
            </Container>
        </Container>
    );
};

export default AdminSellerProductEditor;
