import * as React from "react";
import {
    Avatar,
    Box, Card, Container, IconButton, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import TextFieldComponent from "components/TextField";
import {
    getButtonDataSendApproved,
    getButtonDataSendRejected,
    getButtonDataSendDeleted,
    getButtonDataSendUnlockURL,
    getButtonDataSendEnable,
    getButtonDataSendDisable,
    getButtonDataCancel,
    getTextfieldDataPin,
    getButtonDataSendVerified,
    getButtonDataSendDisplayReq,
    getButtonDataSendDisplayPrinted,
    getButtonDataSendDisplayDelivered,
    getButtonDataSendMsg,
    getTextfieldDataMessage,
    getButtonDataSendInternalMsg,
    getTextfieldDataInternalMessage,
    schema,
    getTextfieldUpdateDomain,
    getButtonUpdateDomain,
    getTextfieldUpdateSocial,
    getButtonUpdateSocial,
} from "./helper";
import {
    checkUserAllowed,
    getLocalDate,
    parseTextWithLinks
} from "helpers/utils";
import MockProductList from "components/elements/main/MockProductList";
import {H2, H5, H6} from "components/CustomTypography";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import {handleExternalPage} from "services/utils";
import {DATE_FORMAT_SHORT, DATE_TIME_FORMAT_SHORT} from "config/constants";
import SelectFieldComponent from "components/SelectField";
import ImageAlbumAdmin from "components/elements/main/ImageAlbumAdmin";
import AdminSellerShopsList from "components/elements/admin/shops/AdminSellerShopsList";
import CustomSectionCreator from "components/CustomSectionCreator";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InstagramIcon from '@mui/icons-material/Instagram';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AdminDisplayDeliveredMain from "components/pages/admin/shops/AdminDisplayDeliveredMain";


const AdminSellerSubscriptionEditor = ({
  setState, selectedShop, symbol,
                                   approvedrejectShopClicked, disableShopClicked, enableShopClicked, updateDomainClicked, updateSocialClicked,
                                   verifiedShopClicked, unlockURLShopClicked,
                                   displayReqClicked, displayDeliveredClicked, displayPrintedClicked, handleSalesLeadClicked,
                                   handleShopInternalMessageClicked, handleOrderMessageClicked,
                                   handleSalesLeadSelected, salesLeadOptions, shopMatchingList,
                                   onCancel, orderdetail, isLoading, userDetails,
}) => {

  const {
     created_at: createdAt, order_id: orderIdValue, status, pin, message, internalmessage, subdomain,
      instahandle, facebookhandle, whatsapphandle
  } = selectedShop || {};

    const {
        plans, messages
    } = orderdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinishApproved = () => {
      approvedrejectShopClicked({ shop_id: orderIdValue, status: "approve" });
  };

    const onFinishRejected = () => {
        approvedrejectShopClicked({ shop_id: orderIdValue, status: "reject" });
    };

    const onFinishDeleted = () => {
        approvedrejectShopClicked({ shop_id: orderIdValue, status: "delete" });
    };

    const onFinishUpdateDomain = () => {
        updateDomainClicked({ shop_id: orderIdValue, subdomain: subdomain });
    };

    const onFinishUpdateSocial = () => {
        updateSocialClicked({ shop_id: orderIdValue, instahandle: instahandle, facebookhandle:  facebookhandle, whatsapphandle:  whatsapphandle });
    };

    const onFinishSendInternalMsg = () => {
        handleShopInternalMessageClicked( orderIdValue, message );
    };

    const onFinishSendMsg = () => {
        handleOrderMessageClicked( orderIdValue, message );
    };

    const onFinishUnlocked = () => {
        unlockURLShopClicked({ shop_id: orderIdValue });
    };

    const onFinishEnable = () => {
        enableShopClicked({ shop_id: orderIdValue });
    };

    const onFinishDisable = () => {
        disableShopClicked({ shop_id: orderIdValue });
    };

    const onFinishVerified = () => {
        verifiedShopClicked(orderIdValue, pin);
    };

    const onFinishDisplayReq = () => {
        displayReqClicked({ shop_id: orderIdValue });
    };

    const onFinishDisplayDelivered = (formData) => {
        displayDeliveredClicked(orderIdValue, formData);
    };

    const onFinishDisplayPrinted = () => {
        displayPrintedClicked({ shop_id: orderIdValue });
    };

    const onFinishSalesLead = (event) => {
        handleSalesLeadClicked(orderIdValue, event.target.value);
    };

    const onSelectingSalesLead = (event) => {
        handleSalesLeadSelected(event.target.value);
    };

    const hasError = () => {
    try {
      return !schema.validateSync(selectedShop);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedShop);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };


    const renderOrderInformation = () => (
        <>
            <Box mt={2}>
                <H2>
                    {orderdetail.order_id}
                </H2>
                <H5>
                    {orderdetail.status}
                </H5>
                <H5>
                    {orderdetail.paymentstatus}
                </H5>
            </Box>
        </>
    );

    const renderSellerInformation = () => (
        <>
            <Box mt={2} mb={2}>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Contact Name:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.seller && orderdetail.seller.name }
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Phone/Email:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.seller && (orderdetail.seller.mobile + " " + orderdetail.seller.email) }
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Added on:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {getLocalDate(orderdetail.order_date, DATE_TIME_FORMAT_SHORT)}
                        </H5>
                    </Box>
                </Box>
            </Box>
        </>
    );

    const renderShopDetail = () => (
        <Box display={window.innerWidth < 400 ? "" : "flex"}>
            <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>

                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Domain:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.shop && orderdetail.shop.domain}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Subdomain:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.shop && orderdetail.shop.subdomain}
                        </H5>
                    </Box>
                </Box>
            </Box>
        </Box>
    );



    const renderShopSendMessages = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Message to Shop owner:"  />
            <Box mb={2}>
                {
                    getTextfieldDataMessage(message).map((element) => (
                        <Box className="textfield" m={2}>
                            <TextFieldComponent
                                required
                                label={element.label}
                                value={element.value}
                                error={!!getError(element.onChangeField)}
                                helperText={getError(element.onChangeField)}
                                onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                            />
                        </Box>
                    ))
                },
                {getButtonDataSendMsg(onFinishSendMsg).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                        size="small"
                    />
                ))
                }
            </Box>
        </Box>
            );

    const renderShopSentMessages = () => (
        <Box>
            <Box mb={2} width="100%">
                {messages && messages.map((messageItem, index) => (
                    <Box width="100%" display="flex">
                        <Box width="30%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {getLocalDate(messageItem.added_at, DATE_FORMAT_SHORT)} ({messageItem.added_by && messageItem.added_by.name})
                            </H5>
                        </Box>
                        <Box width="70%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {parseTextWithLinks(messageItem.message)}
                            </H5>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );


    return (
        <Container>
            <Container maxWidth="md">
                <Box mb={2}>
                    <ButtonComponent
                        type="primary"
                        variant="outlined"
                        name="< Back"
                        size="small"
                        onClick={onCancel}
                    />
                </Box>
                {isLoading && <Box m={10}><MockGeneralHeader  /></Box>}
                {!isLoading && renderOrderInformation()}
                {!isLoading && renderSellerInformation()}
                {!isLoading && renderShopDetail()}

                <Box mb={2}>
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopSendMessages()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopSentMessages()}
                </Box>
            </Container>
        </Container>
    );
};

export default AdminSellerSubscriptionEditor;
