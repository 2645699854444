/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from 'lodash.debounce';

import * as Actions from "actions";
import {
    TextField, Button, IconButton, Container, Typography, Box, Grid, MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import UserProductItem from "components/elements/user/products/UserProductItem";
import UserProductDetail from "components/elements/user/products/UserProductDetail";
import MockProductBox from "components/elements/main/MockProductBox";
import {H5} from "components/CustomTypography";
import {SearchOutlinedIcon} from "../UserCombinedSearch";

import {
    getCategoryBreadcrum,
    getUserProductSortList,
    getCategoryFromID,
    getLocationMarketFromID,
    getLocationBreadcrum,
    isMarketPlaceOnly,
    isFeatureSupported, getLogo, getPlatform
} from "helpers/utils";
import CustomSectionCreator from "components/CustomSectionCreator";
import MobileCategoryNav from "components/mobile-category-nav";
import UserProductCategorySlider from "components/elements/user/products/UserProductCategorySlider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UserShopLocationSlider from "components/elements/user/UserShopLocationSlider";
import CustomFlexBox from "components/CustomFlexBox";
import StoriesPlayer from "components/StoriesPlayer/StoriesPlayer";
import {useLocation} from "react-router-dom";
import UserProductStatusSelector from "components/elements/user/products/UserProductStatusSelector";
import ButtonComponent from "../../../Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SlideshowIcon from "@mui/icons-material/Slideshow";

const UserProductSearch = (props) => {
  const [state, setState] = useState({
    limit: 20,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
    searchvalue: "Search products",
    isShop: false,
  });

  const [productState, setProductState] = useState("");
  const [productModeState, setProductModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);

    const [categoryState, setCategoryState] = useState("");
    const [locationState, setLocationState] = useState("");
    const [marketState, setMarketState] = useState("");
    const [sortState, setSortState] = useState("popular");
    const [offsetState, setOffsetState] = useState(0);

    const [loadStoryState, setLoadStoryState] = useState(false);
    const [loadIdState, setLoadIdState] = useState("");

    const {
        serviceError, reqStatus, isLoadingProducts,
        userMainResetSearchProducts, userMainResetsearchKeywords,
        userMainSearchProducts, userMainSearchKeywords,
        userMainGetProductInfo,
        products, productdetail, searchkeywords,
        userMainResetProductDetail,
        userMainAddProductToWishlist,
        userMainGetProductCategories, commonCategoryList, citylocations,
        currency, match, history,
        isLoadingShop, shopId, isShop, marketId, limit,
        userDetails
    } = props;

  const { keywords } = searchkeywords;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;
  const { sort } = match.params;
  const { location } = match.params;
  const { market } = match.params;
  const { category } = match.params;
    const urlparams = useLocation();

    const { categories } = commonCategoryList;


    useEffect(() => {

        ReactGA.send({
            hitType: "pageview",
            page: "/products",
            title: "Main Products",
            custom: {
                platform: getPlatform(), // Add a custom property to distinguish platforms
            },
        });

        // Get a specific query parameter
        const checkStory = new URLSearchParams(urlparams.search).get('ls');

        if (checkStory) {
            setLoadStoryState(true);
        }

        const loadIndex = new URLSearchParams(urlparams.search).get('li');

        if (loadIndex) {
            setLoadIdState(loadIndex);
        }

        if (category) {
            setCategoryState(getCategoryFromID(categories, category));
        }

        if (sort) {
            setSortState(sort);
        }

        if (query) {
            userMainSearchProducts(query, isShop, shopId, limit, sort, category, locationState, marketId, offsetState);
        } else if (location) {
            // need lat/lng to do query
            setLocationState(location);
            userMainSearchProducts(query, isShop, shopId, limit, sort, category, location, marketId, offsetState);
        } else if (market) {
            setMarketState(market);
            userMainSearchProducts(query, isShop, shopId, limit, sort, category, locationState, marketId, offsetState);
        } else {
            userMainSearchProducts("", isShop, shopId, limit, sort, category, locationState, marketId, offsetState);
        }

    }, []);

  const runSearch = (keyword) => {
      userMainResetsearchKeywords();
    setState({ searchquery: keyword });
    setState({ resetvalue: "" });
    history.push(`/products/search/${keyword}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
        userMainSearchKeywords(value, shopId, isShop);
    }
  };

    const debouncedChangeHandler = useCallback(
        debounce(runKeywordSearch, 300)
        , []);

  const selectKeywordSearch = (value) => {
      history.push(`/products/search/${value}`);
  };

  const handleDelete = () => {
      history.push("/products");
  };


    const addProductFavourite = (prodId) => {
        userMainAddProductToWishlist(prodId)
        setProductModeState("VIEW");
    };


  const handleProductView = (mode, product, index, withStory, reset) => () => {

      if (withStory) {
          setLoadIdState(index);
      }

    setProductModeState(mode);
    if (reset) {
      setProductState({
        selectedProduct: {
            _id: "",
            shop_id: "",
            status: "",
            images: [],
            video: [],
            title: "",
            logo: [],
            area: "",
        },
      });
    } else {

        if (process.env.REACT_APP_ENV === "production") {
            ReactGA.event({
                category: "User Interaction",
                action: "Main Product Clicked : Story - " + withStory,
                label: product.title + " " + product.shop_id + " " + product._id, // Optional
            });
        }

        userMainGetProductInfo(product._id);
      setProductState({
          selectedProduct: {
            _id: product._id,
              shop_id: product.shop_id,
            status: product.status,
            images: product.images,
            video: product.video,
            title: product.title,
            logo: product.logo,
            area: product.area,
        },
      });
    }
  };

    const openCategoryList = () => {
        setProductModeState("CATEG");
    };

    const openStoriesView = () => {
        setProductModeState("STORIES");
    };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);

    const handleSortProducts = (event) => {
        setSortState(event.target.value);
        setOffsetState(0);
        userMainSearchProducts(query, isShop, shopId, "", event.target.value, categoryState._id, locationState, marketId, 0);
    };

    const handleCategoryProducts = (event) => {
        setCategoryState(event.target.value);
        setOffsetState(0);
        userMainSearchProducts(query, isShop, shopId, "", sortState, event.target.value, locationState, marketId, 0);
    };

    const handleCategorySelect = (cat) => {
        setProductModeState("PREVIEW");
        setOffsetState(0);
        setCategoryState(cat);
        userMainSearchProducts(query, isShop, shopId, "", sortState, cat._id, locationState, marketId, 0);
    };


    const handleSelectCategorySet = (cat) => {
        setOffsetState(0);
        setCategoryState(cat);
        userMainSearchProducts(query, isShop, shopId, "", sortState, cat._id, locationState, marketId, 0);
    };

    const handleLocationSet = (location) => {
        setOffsetState(0);
        setLocationState(location.id);
        userMainSearchProducts(query, isShop, shopId, "", sortState, categoryState._id, location.id, marketId, 0);
    };


    const setProductStatus = (sort) => {
        setOffsetState(0);
        setSortState(sort);
        userMainSearchProducts(query, isShop, shopId, "", sort, categoryState._id, locationState, marketId, 0);
    };

    const handleRestAll = () => {
        setOffsetState(0);
        setSortState("");
        setLocationState("");
        setCategoryState("");
        userMainSearchProducts(query, isShop, shopId, "", "", "", "", "");
    };

    const handlePrevOffset = () => {
        const offset = offsetState > 1 ? offsetState - 1 : 0;
        setOffsetState(offset);
        userMainSearchProducts(query, isShop, shopId, "", sortState, categoryState._id, locationState, marketId, offset);
    };

    const handleNextOffset = () => {
        const offset = offsetState + 1;
        setOffsetState(offset);
        userMainSearchProducts(query, isShop, shopId, "", sortState, categoryState._id, locationState, marketId, offset);
    };

    const handleResetResultsStory = () => {
        setProductModeState("PREVIEW");
        setLoadIdState("");
    };

    const handleResetProductStory = () => {
        setLoadIdState("");
    };

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/products/search")) {
      history.push("/products/search");
    }
  };

  let isModalSearch = false;

    const productsort = getUserProductSortList();

    const sortOptions = productsort.map((item) => {
        const { value, label } = item;
        return (
            <MenuItem value={value}>{label}</MenuItem>
        );
    });

    const categoryFilterOptions = categories.map((item) => {
        const { id, name } = item;
        return (
            <MenuItem value={id}>{name}</MenuItem>
        );
    });

  const renderNoResults = () => (
      <Container>
        <Box>
            <Typography variant="h6">
                No item found
            </Typography>
        </Box>
      </Container>
  );

    var type = {
        'new': 'Latest Products',
        'popular': 'Popular Products',
        'default': 'Popular Products'
    };

  const renderResults = () => (
      <div>
          {isFeatureSupported("shopreels") &&
              <CustomFlexBox sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  width: '100%',
                  bottom: 70,
                  justifyContent: 'center',
                  zIndex: 'modal'
              }}>
                  <Button
                      sx={{marginRight: '2px'}}
                      size="large"
                      color="primary"
                      variant="contained"
                      onClick={openStoriesView}
                  >
                      <SlideshowIcon /> &nbsp; View as {process.env.REACT_APP_REELS_TAG}
                  </Button>
              </CustomFlexBox>
          }
          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
              {products.map((product, index) => (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                      <UserProductItem
                          product={product}
                          currency={currency}
                          key={`${index + 1}`}
                          viewProductWithStory={handleProductView("STORIESVIEW", product, index, true,false)}
                          viewProduct={handleProductView("VIEW", product, index, false, false)}
                      />
                  </Grid>
              ))}
          </Grid>
      </div>
  );

    const storiesData = products.map((item) => {
        const {id, shop_id, images, video, title, logo, area} = item;

        let stories = [];

        if (video && video.length > 0) {
            stories.push({
                video: video[0].cdn.secure_url,
            });
        } else {
            images && images.map((product) => (
                stories.push({
                    image: product.cdn.secure_url,
                })
            ));
        }


        return (
            {
                user: {
                    avatar: getLogo(logo),
                    name: title,
                    userLink: "/#/shop/" + shop_id,
                    area: area,
                },
                stories: stories,
            }
        );
    });

    const renderResultsAsStories = () => (
        <div>
            <StoriesPlayer
                loadIndex={loadIdState}
                storiesData={storiesData}
                onCancel={() => handleResetResultsStory()}
            />
        </div>
    );

    const renderProduct = () => (

        <UserProductDetail
            setState={(value, field) => setProductState({
                ...state,
                selectedProduct: {
                    ...selectedProduct,
                    [field]: value,
                },
            })}
            currency={currency}
            userDetails={userDetails}
            isLoading={isLoadingProducts}
            productdetail={productdetail}
            selectedProduct={selectedProduct}
            onAddToFavlistClicked={addProductFavourite}
            history={history}
            onCancel={() => setProductModeState("PREVIEW")}
        />

    );

    const renderProductWithStoriesPlayer = (productData) => (

        <div>

            {renderProduct()}

            <StoriesPlayer
                storiesData={productData}
                onCancel={() => handleResetProductStory()}
            />
        </div>

    );

  const renderSearchHelp = () => (
      <Container>
          <Box>
              {
                  window.innerWidth <= 500
                  && (match.path === "/products/search")
                  && optionValues.map((element) => (
                      <Box m={2} alignItems="center" width="100%" display="flex" onClick={() => selectKeywordSearch(element.label)}>
                          <Box width="85%">
                              <H5 fontSize={16} ellipsis>
                                  {element.label}
                              </H5>
                          </Box>
                          <Box width="15%">
                              <IconButton disabled align="right" size="small">
                                  <ArrowForwardIcon />
                              </IconButton>
                          </Box>
                      </Box>
                  ))
              }
          </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box mb={2}>
        {
            window.innerWidth <= 500
            && (match.path === "/products/search") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete1"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={handleKeyPress}
          onSelect={(input) => debouncedChangeHandler(input.target.value)}
          options={window.innerWidth <= 500 ? [] : optionValues.map((element) => element.label)}
          renderInput={(params) => (
            <TextField
                {...params}
                InputProps={{
                    sx: {
                        height: 44,
                        paddingRight: 0,
                        borderRadius: 0,
                        color: "grey.900",
                        overflow: "hidden",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                        },
                    },
                    startAdornment: <SearchOutlinedIcon fontSize="small" />,
                }}
              autoFocus={isModalSearch}
              placeholder={`Search ${process.env.REACT_APP_PRODUCT_TITLE}`}
            />
          )}
        />
        </Box>
      </Container>

        {
            renderSearchHelp()
        }


      <Container>
      { match.path !== "/"
      && (
          match.path === "/products/search/:query" ||
          match.path === "/products/search/:query" ||
          match.path === "/products" ||
          match.path === "/products/sort/:sort" ||
          match.path === "/products/location/:location" ||
          match.path === "/products/market/:market" ||
          match.path === "/products/category/:category"
      ) && (
      <>

              <Box width="100%" margin="0px">
                  <UserProductCategorySlider
                      {...props}
                      isDirect={false}
                      includeAll={true}
                      openCategoryList={openCategoryList}
                      selectedCategory={categoryState._id}
                      handleSelectCategoryLink={handleSelectCategorySet}
                      handleSelectCategorySet={handleSelectCategorySet}
                  />
              </Box>
              { isMarketPlaceOnly() && sortState !== "nearby" &&
                  <Box width="100%" margin="0px">
                    <UserShopLocationSlider
                        {...props}
                        isDirect={false}
                        isProduct={true}
                        setLocation={locationState}
                        headerTitle={"By Location: " + (type[sortState] || type['default'])}
                        handleSelectCategoryLink={handleLocationSet}
                        handleSelectCategorySet={handleLocationSet} />
                    </Box>}

          {query && (<CustomSectionCreator title={"Searching: '" + query + "'"} />)}
          {/*!query && (categoryState ? (<CustomSectionCreator title={getCategoryBreadcrum(categories, categoryState) + getLocationBreadcrum(citylocations, locationState)} />) : (<CustomSectionCreator title={(type[sortState] || type['default']) + getLocationBreadcrum(citylocations, locationState)} />)) */}

          <CustomFlexBox alignItems="center" mb={2} sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
          }}>
              <Box width="50%">
                  <UserProductStatusSelector
                      onStatusSelect={setProductStatus}
                      productstatus={sortState}
                  />
              </Box>
              <Box width="15%">
                  <ButtonComponent
                      type="primary"
                      variant="outlined"
                      name="All"
                      size="medium"
                      onClick={handleRestAll}>
                  </ButtonComponent>
              </Box>
              <Box width="30%">
                  {!(isLoadingProducts) && products.length > 0 && <CustomFlexBox sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                  }}>
                      <Box width="50%" align="right">
                          {offsetState > 0 && <Button
                              size="medium"
                              color="primary"
                              variant="outlined"
                              onClick={handlePrevOffset}
                          >
                              <ArrowBackIosIcon fontSize="small" color="primary"/>
                          </Button>
                          }
                      </Box>
                      <Box width="50%" align="right">
                          <Button
                              size="medium"
                              color="primary"
                              variant="outlined"
                              onClick={handleNextOffset}
                          >
                              <ArrowForwardIosIcon fontSize="small" color="primary" />
                          </Button>
                      </Box>
                  </CustomFlexBox>
                  }

              </Box>
          </CustomFlexBox>

          {isLoadingProducts ? <MockProductBox /> : products.length > 0 ? renderResults() : renderNoResults()}
          {!isLoadingProducts && products.length > 0 && loadStoryState === true ? (openStoriesView(), setLoadStoryState(false)) : ""}
      </>
      )}
      </Container>
    </>
  );

  const renderProductMode = (productMode) => {
    switch (productMode) {

        case "STORIES":
            return renderResultsAsStories();

        case "CATEG":
            return (
        <MobileCategoryNav
            categories={categories}
            categoryState={categoryState}
            selectCategoryClicked={handleCategorySelect}
        />
            );

        case "STORIESVIEW":

            const {id, shop_id, images, video, title, logo, area} = selectedProduct;

            let productStoryData = [];
            let stories = [];

            if (video && video.length > 0) {
                stories.push({
                    video: video[0].cdn.secure_url,
                });
            } else {
                images && images.map((product) => (
                    stories.push({
                        image: product.cdn.secure_url,
                    })
                ));
            }


            productStoryData.push(
                {
                    user: {
                        avatar: logo[0].cdn.secure_url,
                        name: title,
                        userLink: "/#/shop/" + shop_id,
                        area: area,
                    },
                    stories: stories,
                }
            );

            return renderProductWithStoriesPlayer(productStoryData);

        case "VIEW":
            return renderProduct();

        case "PREVIEW":
        default:
            return renderSearchModule();
    }
  };

  const { selectedProduct = {} } = productState;

  return (
      <Container>
        {renderProductMode(productModeState)}
      </Container>
  );


};

UserProductSearch.defaultProps = {
  currency: "",
};

UserProductSearch.propTypes = {
    currency: PropTypes.string,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    products: PropTypes.arrayOf(PropTypes.any).isRequired,
    searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
    commonCategoryList: PropTypes.objectOf(PropTypes.any).isRequired,
    citylocations: PropTypes.objectOf(PropTypes.any).isRequired,
    userMainSearchProducts: PropTypes.func.isRequired,
    userMainSearchKeywords: PropTypes.func.isRequired,
    userMainResetsearchKeywords: PropTypes.func.isRequired,
    userMainResetSearchProducts: PropTypes.func.isRequired,
    userMainGetProductInfo: PropTypes.func.isRequired,
    userMainAddProductToWishlist: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userProductSearch = connect(
  ({ userMainProducts, bootupInfo, userMainShops }) => ({
      bootupInfo,
      userDetails: bootupInfo.userDetails,
      currency: bootupInfo.symbol,
      reqStatus: userMainProducts.reqStatus,
      isLoadingProducts: userMainProducts.isLoading,
      serviceError: userMainProducts.serviceError,
      products: userMainProducts.searchproducts,
      searchkeywords: userMainProducts.searchkeywords,
      searchcategorykeywords: userMainProducts.searchcategorykeywords,
      commonCategoryList: userMainProducts.categoryList,
      citylocations: bootupInfo.citylocations,
      productdetail: userMainProducts.productdetail,
      shopDetail: userMainShops.shopDetail,
      isLoadingShop: userMainShops.isLoading,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductSearch);

export { userProductSearch as default };
