import * as yup from "yup";

export const schema = yup.object().shape({
  price: yup.string().required().matches(/^[0-9.]+$/, "Must be number"),
  is_popular: yup.boolean().default(true),
  is_price_visible: yup.boolean().default(true),
  is_active: yup.boolean().default(true),
  in_stock: yup.boolean().default(true),
});

export const getTextfieldData = (price) => [
  {
    label: "Price",
    value: price,
    onChangeField: "price",
  },
];

export const getCheckboxData1 = (is_price_visible, is_price_onunlock) => [
  {
    label: "Show price on site",
    value: is_price_visible,
    onChangeField: "is_price_visible",
  },
  {
    label: "Only on Like!",
    value: is_price_onunlock,
    onChangeField: "is_price_onunlock",
  },
];

export const getCheckboxData2 = (is_popular, is_listing_active, in_stock) => [
  {
    label: "Popular",
    value: is_popular,
    onChangeField: "is_popular",
  },
  {
    label: "Active on site",
    value: is_listing_active,
    onChangeField: "is_listing_active",
  },
  {
    label: "Show as 'In stock''",
    value: in_stock,
    onChangeField: "in_stock",
  },
];

export const getCheckboxSize = (XS, S, M, L, XL, XXL) => [
  {
    label: "XS",
    value: XS,
    onChangeField: "XS",
  },
  {
    label: "S",
    value: S,
    onChangeField: "S",
  },
  {
    label: "M",
    value: M,
    onChangeField: "M",
  },
  {
    label: "L",
    value: L,
    onChangeField: "L",
  },
  {
    label: "XL",
    value: XL,
    onChangeField: "XL",
  },
  {
    label: "XXL",
    value: XXL,
    onChangeField: "XXL",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];
