/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, {useState, useEffect, useCallback} from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Typography, FormControl, Radio, RadioGroup, FormControlLabel, Box, Card, IconButton, Container, Divider, MenuItem,
} from "@mui/material";
import {getImage, getPriceCurrency, isMarketPlace} from "helpers/utils";

import ShopHeader from "components/elements/user/shops/ShopHeader";
import ButtonComponent from "components/Button";
import CustomTextField from "components/CustomTextField";
import SelectFieldComponent from "components/SelectField";
import { H2, H4 } from "components/CustomTypography";

import * as Actions from "actions";
import * as ActionsBase from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";

import { getCartTotal } from "services";
import { useHistory } from "react-router-dom";
import {Notification, Pace} from "components/Atoms";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import CustomSectionCreator from "components/CustomSectionCreator";

const SubscriptionCheckout = (props) => {

  const {
    isLoading, subscriptioncart, getSubscriptionsCart, checkoutSubscriptionPlanOrder, orderplaced,
  } = props;

  const [state, setState] = useState({
    coupon: ""
  });

  useEffect(() => {
    getSubscriptionsCart();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };
  useEffect(() => {

    if (props.serviceError) {
      const feedback = {
        title: props.serviceError,
        description: props.serviceError.statusText,
      };
      sendNotification("error", feedback);
    }

    switch (props.reqStatus) {
      case ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_SUCCESS:
        if (props.serviceCode === 200) {
            props.history.push({
              pathname: "/subscriptions/payment-page",
              state: {
                // payment: { paymentID: props.order.order.order_id },
                order_id: orderplaced.order.order_id,
                _id: orderplaced.order._id,
                status: orderplaced.order.status,
                items: orderplaced.order.plans,
                coupon: orderplaced.order.coupon,
                created_at: orderplaced.order.created_at,
                order_type: orderplaced.order.order_type,
                total_price: orderplaced.order.total_price,
                total_discount: orderplaced.order.total_discount,
                total_service: orderplaced.order.total_service,
                total_order: orderplaced.order.total_order,
                total_tax: orderplaced.order.total_tax
              },
            });
        }
        break;
      default:
        break;
    }
  }, [props.reqStatus]);


  const handleCoupon = (event) => {
    setState({ ...state, coupon: event.target.value });
  };


  const handleCheckoutOrder = () => {
    checkoutSubscriptionPlanOrder(state);
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const {
    order, cartItems, currency, total, shippingAddress,
  } = props;



  const getPrice = (symbol, item) => {
    const price = item.seller_price
        - (item.product && item.product.discount
          ? (item.seller_price * (parseInt(item.product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {currency}
        {price}
        {item.product.discount && (
          <del>
            <span className="money">
              {currency}
              {item.seller_price}
            </span>
          </del>
        )}
      </Typography>
    );
  };

  const renderCartItems = () => (
    <Box>
        {subscriptioncart.map((item, index) => (
          <Box className="styled-card" width="100%" display="flex" p={1}>
            <Box width="80%" order={1} ml={2}>
                <H2 mb={2} fontSize={16}  ellipsis>
                  {item.plan.title}
                </H2>
                <H4 fontSize={12}>
                  {item.plan.description}
                </H4>
            </Box>
            <Box width="20%" order={2} ml={2}>
              <H2 mb={2} fontSize={16}  ellipsis>
                {getPriceCurrency(item.price, currency)}
              </H2>
            </Box>
          </Box>
        ))}
    </Box>
  );


  return (
    <div>
      <ShopHeaderNav history={history} />
      {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
      <CustomSectionCreator isSubtle={true} title="Buy Promotion Package" />
      <form>
        <Container>

          {renderCartItems()}

          <CustomTextField name="coupon" onChange={(event) => handleCoupon(event)} />
          <div className="payment-box">
                <Typography className="m-2" align="center">
                  <ButtonComponent
                    variant="contained"
                    type="primary"
                    name="Continue"
                    fullWidth
                    onClick={() => handleCheckoutOrder()}
                  />
                </Typography>
          </div>

        </Container>
      </form>
    </div>

  );
};

// Mapping Props and State.
const subscriptionCheckout = connect(
  ({ subscriptions, bootupInfo }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    reqStatus: subscriptions.reqStatus,
    isLoading: subscriptions.isLoading,
    serviceError: subscriptions.serviceError,
    serviceCode: subscriptions.serviceCode,
    subscriptioncart: subscriptions.subscriptioncart,
    orderplaced: subscriptions.orderplaced,
    total: getCartTotal(subscriptions.subscriptioncart),
  }),
  (dispatch) => bindActionCreators({ ...Actions, ...ActionsBase }, dispatch),
)(SubscriptionCheckout);

export { subscriptionCheckout as default };
