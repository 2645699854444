// @flow
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Container, Grid,
  MenuItem, CircularProgress, Box, IconButton, Typography,
} from "@mui/material";
import {H2, H6, SpanInline} from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import {
  Button,
  Notification,
  AutoComplete,
  Divider,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.products";
import * as ActionTypes from "constants/ActionTypes";

import SellerCategorySelector from "components/elements/seller/SellerCategorySelector";
import PrivateProductEditor from "components/elements/seller/PrivateProductEditor";
import SellerProductEntryForm from "components/elements/seller/SellerProductEntryForm";
import SellerProduct from "components/elements/seller/SellerProduct";

import PrivateProductImageMain from "components/pages/seller/products/PrivateProductImageMain";
import PrivateProductQuickAddMain from "components/pages/seller/products/PrivateProductQuickAddMain";

import MockProductList from "components/elements/main/MockProductList";

import { CURRENCY } from "config/constants";

import "./style.scss";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ButtonComponent from "components/Button";
import SellerProductStatusSelector from "components/elements/seller/SellerProductStatusSelector";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PrivateCategoryMain from "../PrivateCategoryMain";
import CustomSectionCreator from "../../../../CustomSectionCreator";

const { Option } = AutoComplete;

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box>
              <Typography>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const PrivateProductsMain = (props) => {
  const [state, setState] = useState({ productMode: "PREVIEW" });
  const [categoryState, setCategoryState] = useState({ selectedCategory: (props.categoryInput ? props.categoryInput : null) });
  const [productState, setProductState] = useState({ storeProduct: "" });
  const [offsetState, setOffsetState] = useState(0);
  const [productStatusState, setProductStatusState] = useState("new");
  const [value, setValue] = useState(0);

  const {
    serviceError, reqStatus, isLoading, storeProducts, categoryList, symbol, currency,
    getStoreProductCategories, getStoreProducts,
  } = props;

  useEffect(() => {
    getStoreProductCategories();
    getStoreProducts(null, true, 0);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: props.serviceError,
        description: props.serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_QUICK_SUCCESS:
        setState({ productMode: "PREVIEW" });
      case ActionTypes.ADD_PRIVATE_PRODUCT_SUCCESS:
      case ActionTypes.UPDATE_PRIVATE_PRODUCT_SUCCESS:
      case ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS:
      case ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS:
      case ActionTypes.UPDATE_PRODUCT_SUCCESS:
        props.getStoreProducts(categoryState.selectedCategory, true);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateProductToAccountCall = (values) => {
    if (!values.product_id) {
      props.addPrivateProduct(values);
    } else {
      props.updatePrivateProduct(values);
    }
    setState({ productMode: "PREVIEW" });
  };

  const updateProductSettingCall = (values, id) => {
    values.id = id;
    props.updateProduct(values);
    setState({ productMode: "PREVIEW" });
  };

  const handleProductEdit = (mode, item, reset) => (e) => {
    setState({ productMode: mode });
    if (reset) {
      setProductState({
        storeProduct: {
          name: "",
          price: "",
          description: "",
          category: "",
          is_food: false,
          is_nonveg: false,
          id: "",
          prod_id: "",
          product_id: "",
          listing_id: "",
          images: [],
          size: [],
        },
      });
    } else {
      item.category = item.categories.length > 0 ? item.categories[0]._id : "NA";
      setProductState({
        storeProduct: {
          name: item.name,
          description: item.description,
          category: item.category,
          is_food: item.is_food,
          is_nonveg: item.is_nonveg,
          price: item.price,
          id: item.id,
          prod_id: item.prod_id,
          product_id: item.product_id,
          listing_id: item.listing_id,
          images: item.images,
          size: item.size,
        },
      });
    }
  };

  const handleProductEditing = (mode, item) => (e) => {

    setValue(0);
    setState({ productMode: mode });

    setProductState({
      storeProduct: {
        name: item.name,
        description: item.description,
        category: item.category,
        price: item.price,
        id: item.id,
        prod_id: item.prod_id,
        product_id: item.product_id,
        listing_id: item.listing_id,
        is_popular: item.is_popular,
        is_price_visible: item.is_price_visible,
        is_price_onunlock: item.is_price_onunlock,
        is_listing_active: item.is_listing_active,
        in_stock: item.in_stock,
        is_food: item.is_food,
        is_nonveg: item.is_nonveg,
        size: item.size,
        images: item.images,
      },
    });
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const resetCategory = () => {
    setCategoryState({ selectedCategory: null });
    setOffsetState(0);
    getStoreProducts(null, true, productStatusState, 0);
  };

  const setCategory = (category) => {
    setCategoryState({ selectedCategory: category.id });
    setOffsetState(0);
    getStoreProducts(category.id, true, productStatusState, 0);
  };

  const setProductStatus = (status) => {
    setProductStatusState(status);
    setOffsetState(0);
    getStoreProducts(categoryState, true, status, 0);
  };

  const handleRestAll = () => {
    setOffsetState(0);
    setProductStatusState("");
    getStoreProducts(null, true, "", 0);
  };

  const handlePrevOffset = () => {
    const offset = offsetState > 30 ? offsetState - 30 : 0;
    setOffsetState(offset);
    getStoreProducts(null, true, productStatusState, offset);
  };

  const handleNextOffset = () => {
    const offset = offsetState + 30;
    setOffsetState(offset);
    getStoreProducts(null, true, productStatusState, offset);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { records } = storeProducts;
  const { categories } = categoryList;
  const { productMode } = state;
  const { storeProduct = {} } = productState;
  const { selectedCategory = {} } = categoryState;

  const inputOptions = categories.map((item) => {
    const { id, name } = item;
    return (
      <MenuItem value={id}>{name}</MenuItem>
    );
  });

  const renderNoResults = () => (
    <section className="cart-section section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div>
              <div className="col-sm-12 empty-cart-cls text-center">
                <H6>No product found
                </H6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const renderResults = () => (
        <div>
              {records.length > 0
                      ? (
                          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                            {records.map((product, index) => (
                                <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                                  <SellerProduct
                                      className="product-item"
                                      symbol={symbol}
                                      currency={currency}
                                      key={product.id}
                                      product={product}
                                      editProduct={handleProductEditing("EDIT", product)}
                                  />
                                </Grid>
                            ))}
                          </Grid>
                      )
                      : (
                          <div className="row">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                              <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="" />
                              <h3>Sorry! Couldn`t find the product you were looking For!!!    </h3>
                              <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                            </div>
                          </div>
                      )}
          <Box>&nbsp;<br />&nbsp;</Box>
        </div>
  );

  const renderProductList = () => (
      <Box mt={2} sx={{ width: "100%" }}>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          width: '100%',
          bottom: 70,
          justifyContent: 'center',
          zIndex: 'modal' }}>
          <Button
              sx={{marginRight: '2px'}}
              size="large"
              color="primary"
              variant="contained"
              onClick={handleProductEdit("EDIT_QUICK", null, true)}
          >
            Quick Add
          </Button>
          <Button
              sx={{marginLeft: '2px'}}
              size="large"
              color="primary"
              variant="contained"
              onClick={handleProductEdit("ADD", null, true)}
          >
            Add New {process.env.REACT_APP_PRODUCT_TAG}
          </Button>
        </CustomFlexBox>

        <Box mt={2}>
        <SellerCategorySelector
            title="All Product Categories"
            categoryList={categoryList}
            handlePage={handlePage}
            resetCategory={resetCategory}
            onCategorySelect={setCategory}
            selectedCategory={selectedCategory}
        />
        </Box>

        <CustomFlexBox mt={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box width="50%">
            <SellerProductStatusSelector
                onStatusSelect={setProductStatus}
                productstatus={productStatusState}
            />
          </Box>
          <Box width="15%" align="right">
            <ButtonComponent
                sx={{margin: "4px"}}
                type="primary"
                variant="contained"
                name="Reset"
                size="small"
                onClick={handleRestAll}>
            </ButtonComponent>
          </Box>


          <Box width="30%">
            {!(isLoading) && records.length > 0 && <CustomFlexBox m={2} sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}>
              <Box width="50%" align="right">
                {offsetState > 0 && <IconButton onClick={handlePrevOffset}>
                  <ArrowBackIosIcon fontSize="medium" color="primary"/>
                </IconButton>
                }
              </Box>
              <Box width="50%" align="right">
                <IconButton onClick={handleNextOffset}>
                  <ArrowForwardIosIcon fontSize="medium" color="primary"/>
                </IconButton>
              </Box>
            </CustomFlexBox>
            }
          </Box>
        </CustomFlexBox>


        {isLoading ? <MockProductList />
            : records.length > 0
                ? renderResults()
                : renderNoResults()}
      </Box>
  );

  const renderProductEdit = () => {
    switch (productMode) {
      case "ADD":
        return (
            <PrivateProductEditor
                setState={(value, field) => setProductState({
                  ...state,
                  storeProduct: {
                    ...storeProduct,
                    [field]: value,
                  },
                })}
                product={storeProduct}
                categoriesOption={inputOptions}
                addUpdateProductToShopClicked={addUpdateProductToAccountCall}
                addCategoryClicked={handlePage("/products/categories")}
                cancelEdit={() => setState({ productMode: "PREVIEW" })}
            />
        );

      case "EDIT_QUICK":
        return (
            <PrivateProductQuickAddMain
                cancelEdit={() => setState({ productMode: "PREVIEW" })}
                autoClose={() => setState({ productMode: "PREVIEW" })}
            />
        );
      case "EDIT":
      default:
        return (
        <Box mt={2} sx={{ width: "100%" }}>
          <H2>{storeProduct.name}</H2>
          <Box mt={2} mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value}
                  sx={{
                    '& .MuiTab-root': {
                      backgroundColor: 'grey.300',
                    },
                    '& .Mui-selected': {
                      backgroundColor: "primary.100",
                      color: 'white',
                    },
                  }}
                  indicatorColor="primary"
                  onChange={handleTabChange}
                  variant="fullWidth"
                  aria-label="">
                <Tab label="Settings" {...a11yProps(0)} />
                <Tab label="Details" {...a11yProps(1)} />
                <Tab label="Photos" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} >
            <SellerProductEntryForm
                setState={(value, field) => setProductState({
                  ...state,
                  storeProduct: {
                    ...storeProduct,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                product={storeProduct}
                updateProductSettingClicked={updateProductSettingCall}
                cancelEdit={() => setState({ productMode: "PREVIEW" })}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PrivateProductEditor
                setState={(value, field) => setProductState({
                  ...state,
                  storeProduct: {
                    ...storeProduct,
                    [field]: value,
                  },
                })}
                product={storeProduct}
                categoriesOption={inputOptions}
                addUpdateProductToShopClicked={addUpdateProductToAccountCall}
                addCategoryClicked={handlePage("/products/categories")}
                cancelEdit={() => setState({ productMode: "PREVIEW" })}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PrivateProductImageMain
                product={storeProduct}
                cancelEdit={() => setState({ productMode: "PREVIEW" })}
            />
          </TabPanel>
        </Box>
        );
    }
  };

  return (
      <Container>
      {productMode === "PREVIEW" ? (
        renderProductList()
      ) : (
        renderProductEdit(productMode)
      )}
    </Container>
  );
};

PrivateProductsMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  storeProducts: [],
  categoryList: [],
  symbol: CURRENCY,
};

PrivateProductsMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  storeProducts: PropTypes.arrayOf(PropTypes.object),
  categoryList: PropTypes.arrayOf(PropTypes.object),
  symbol: PropTypes.string,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  getStoreProducts: PropTypes.func.isRequired,
  getStoreProductCategories: PropTypes.func.isRequired,
  addPrivateProduct: PropTypes.func.isRequired,
  updatePrivateProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
};

const privateProductsMain = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo,
    symbol: bootupInfo.symbol,
    currency: bootupInfo.symbol,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    storeProducts: products.storeProducts,
    categoryList: products.storeProductCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(PrivateProductsMain);

export default privateProductsMain;
