// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";
import {
  checkIfCityChanging,
  getGeoLocation,
  getGeoLocationDue,
  getGeoLocationLatLon, getOnLoadCityCode, getOnLoadSavedCity, getSelectedCity,
  isLocationInSupportedCity, isOnLoadCitySaved, setCity, setLoadCitySaved, updateLastCheckTime,
  updateUserLocation
} from "helpers/utils";
import querystring from "querystring";

function* userMainGetLocationStoreList(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }


  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: "",
      location: "nearby",
      market: "",
      name: "",
      offset: "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const shops = yield API.get(`/users/shops/get-shops`  + query
    );
    yield put(Actions.userMainGetLocationStoreListSuccess(shops));
  } catch (error) {
    yield put(Actions.userMainGetLocationStoreListFailure(error));
  }
}

function* userMainGetExactLocationStoreList(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }

        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }


  try {

    const searchQuery = querystring.stringify({

      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: "",
      location: "exact",
      market: "",
      name: "",
      offset: "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const shops = yield API.get(`/users/shops/get-shops`  + query
    );
    yield put(Actions.userMainGetExactLocationStoreListSuccess(shops));
  } catch (error) {
    yield put(Actions.userMainGetExactLocationStoreListFailure(error));
  }
}

function* userMainGetPopularStoreList(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: "popular",
      location: "",
      market: action.market ? action.market : "",
      name: "",
      offset: "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(`/users/shops/get-shops` + query
    );
    yield put(Actions.userMainGetPopularStoreListSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetPopularStoreListFailure(error));
  }
}

function* userMainGetLatestStoreList(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: "new",
      location: "",
      market: action.market ? action.market : "",
      name: "",
      offset: "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(`/users/shops/get-shops` + query
    );
    yield put(Actions.userMainGetLatestStoreListSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetLatestStoreListFailure(error));
  }
}

function* userMainSearchShops(action: Object) {

  let coordinates;

    try {
      if (navigator.geolocation) {
        if (getGeoLocationDue()) {
          const usergetUserGeoLocation = () =>
              new Promise((resolve, reject) => {
                var options = {
                  timeout: 5000,
                  maximumAge: 0,
                  enableHighAccuracy: true
                };
                navigator.geolocation.getCurrentPosition(
                    (location) => resolve(location),
                    (error) => reject(error),
                    options
                );
              });

          const response = yield call(usergetUserGeoLocation);
          const {latitude, longitude} = response.coords;

          var currentCity = isLocationInSupportedCity(response.coords);

          if (currentCity) {
            if (checkIfCityChanging(currentCity)) {
              yield put(Actions.getCityLocations(currentCity.id));
            }
            updateUserLocation(response.coords, currentCity);
            coordinates = {lat: latitude, lng: longitude};
          } else {
            updateLastCheckTime();
            coordinates = getGeoLocation();
          }
        } else {

          if (getOnLoadCityCode()) {
            if (!isOnLoadCitySaved()) {
              var selectedCity = getOnLoadSavedCity();
              if (selectedCity) {
                setLoadCitySaved();
                if (checkIfCityChanging(selectedCity)) {
                  setCity(selectedCity.id);
                  yield put(Actions.getCityLocations(selectedCity.id));
                }
              }
            }
          }

          coordinates = getGeoLocation();

        }
      }
    } catch
        (error) {
      // Reset time if running into error - check again after some time
      updateLastCheckTime();
      // get earlier or default location
      coordinates = getGeoLocation();
    }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: action.sort ? action.sort : "",
      location: action.location ? action.location : "",
      market: action.market ? action.market : "",
      name: action.payload,
      offset: action.offset ? action.offset : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/shops/get-shops` + query
    );
    yield put(Actions.userMainSearchShopsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchShopsFailure(error));
  }
}

function* userMainSearchShopKeywords(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: action.sort ? action.sort : "nearby",
      name: action.payload
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/shops/get-keywords` + query
    );
    yield put(Actions.userMainSearchShopKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchShopKeywordsFailure(error));
  }
}

function* userMainGetShopInfoById(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-shopinfo?shop=`+ searchQuery
    );
    yield put(Actions.userMainGetShopInfoByIdSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetShopInfoByIdFailure(error));
  }
}

function* userMainGetStoreFavlist(action: Object) {
  try {

    const searchQuery = querystring.stringify({
      market: action.marketId ? action.marketId : ""
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/shops/shoplist/get-shoplist` + query,
        { withCredentials: true }
    );
    yield put(Actions.userMainGetStoreFavlistSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetStoreFavlistFailure(error));
  }
}

function* userMainAddStoreToFavlist(action: Object) {
  try {
    const response = yield API.post(
        `users/shops/shoplist/add-shop`,
        { "shop_id" : action.shopId},
        { withCredentials: true }
    );
    yield put(Actions.userMainAddStoreToFavlistSuccess(response, action.shopId));
  } catch (error) {
    yield put(Actions.userMainAddStoreToFavlistFailure(error));
  }
}

function* userMainRemoveStoreFromFavlist(action: Object) {
  try {
    const response = yield API.post(
        `users/shops/shoplist/remove-shop`,
        { "shop_id" : action.payload.shop._id},
        { withCredentials: true }
    );
    yield put(Actions.userMainRemoveStoreFromFavlistSuccess(response, action.payload.shop._id));
  } catch (error) {
    yield put(Actions.userMainRemoveStoreFromFavlistFailure(error));
  }
}


export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_START,   userMainGetLocationStoreList);
  yield takeLatest(ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_START,   userMainGetExactLocationStoreList);
  yield takeLatest(ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_START,    userMainGetPopularStoreList);
  yield takeLatest(ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_START,     userMainGetLatestStoreList);
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_SHOPS_START,              userMainSearchShops);
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORD_START,      userMainSearchShopKeywords);
  yield takeLatest(ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_START,        userMainGetShopInfoById);

  yield takeLatest(ActionTypes.USER_MAIN_GET_STORE_FAVLIST_START,         userMainGetStoreFavlist);
  yield takeLatest(ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_START,      userMainAddStoreToFavlist);
  yield takeLatest(ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_START, userMainRemoveStoreFromFavlist);
}
