import * as React from "react";

import {Box, Container, FormControlLabel, Radio, Typography} from "@mui/material";
import { H2, H4, H5 } from "components/CustomTypography";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CheckboxComponent from "components/Checkbox";
import PropTypes from "prop-types";
import {
  schema, getTextfieldData, getButtonData, getCheckboxData1, getCheckboxData2, getCheckboxSize,
} from "./helper";
import { CURRENCY } from "config/constants";
import {useState} from "react";

const SellerProductEntryForm = ({
  setState, product, updateProductSettingClicked, cancelEdit, symbol,
}) => {
  const {
    name, id, price, is_popular, is_price_visible, is_price_onunlock, is_listing_active, in_stock, size, product_id, listing_id
  } = product || {};

  const [participants, setParticipants] = useState(size);
  const [currentPriceVisible, setCurrentPriceVisible] = useState(is_price_visible);
  const [isPriceOnunlock, setIsPriceOnunlock] = useState(is_price_onunlock);

  const XS = size.includes("XS");
  const S = size.includes("S");
  const M = size.includes("M");
  const L = size.includes("L");
  const XL = size.includes("XL");
  const XXL = size.includes("XXL");

  const [blur, setblur] = React.useState({
    price: false,
    is_popular: false,
    is_price_visible: false,
    is_price_onunlock: false,
    is_listing_active: false,
    in_stock: false,
    size: false,
    XS: false,
    S: false,
    M: false,
    L: false,
    XL: false,
    XXL: false,
  });

  const onFinish = () => {
    updateProductSettingClicked({
      price, is_popular, is_price_visible: currentPriceVisible, is_price_onunlock: isPriceOnunlock, is_listing_active, in_stock, size: participants
    }, listing_id);
  };

  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleCheckBoxChange = (event, field) => {
    const isChecked = event.target.checked;

    if (field === "is_price_visible") {
      setCurrentPriceVisible(isChecked);

      // if price not visable, disable on lock as well
      if (!isChecked) {
        setIsPriceOnunlock(isChecked);
      }
    }

    if (field === "is_price_onunlock") {
      setIsPriceOnunlock(isChecked); // Update local state
    }
    setState(isChecked, field);
    setblur({ ...blur, [field]: true });
  };

  const handleCheckBoxListChange = (event, field) => {

    setblur({ ...blur, [field]: true });

    const value = field;
    let newlist = participants;

    newlist = newlist.includes(value) ? newlist.filter((f) => f !== value) : [...newlist, value];
    setParticipants(newlist);
    setState(newlist, "size");
  };

  const hasError = () => {
    try {
      return !schema.validateSync(product);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.price || blur.is_popular || blur.is_price_visible || blur.is_price_onunlock || blur.is_listing_active || blur.in_stock ||
      blur.XS || blur.S || blur.M || blur.L || blur.XL || blur.XXL);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, product);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box>
        {getTextfieldData(price).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label + " (" + symbol + ")"}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
            />
          </Box>
        ))}

        <Box className="textfield"
             display="flex"
             flexDirection="row"
             alignItems="left">
        {getCheckboxData1(currentPriceVisible, isPriceOnunlock)
            .filter(element => !(currentPriceVisible === false && element.onChangeField === 'is_price_onunlock'))
            .map((element) => (
          <Box className="textfield"
               display="flex"
               m={2}>
                <CheckboxComponent
                    required
                    label={element.label}
                    value={element.value}
                    onChange={(event) => handleCheckBoxChange(event, element.onChangeField)}
                />
          </Box>
          ))}
        </Box>

        {getCheckboxData2(is_popular, is_listing_active, in_stock)
            .map((element) => (
                <Box className="textfield" m={2}>
                      <CheckboxComponent
                          required
                          label={element.label}
                          value={element.value}
                          onChange={(event) => handleCheckBoxChange(event, element.onChangeField)}
                      />
                </Box>
            ))}

        <H4>Size available:</H4>
        <H5>(Do not select for 1 size items)</H5>
        <Box display="flex" flexWrap="wrap">
        {getCheckboxSize(XS, S, M, L, XL, XXL).map((element) => (
            <Box m={2}>
              <CheckboxComponent
                  required
                  label={element.label}
                  value={element.value}
                  onChange={(event) => handleCheckBoxListChange(event, element.onChangeField)}
              />
            </Box>
        ))}
        </Box>
      </Box>
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            type={element.type}
            variant={element.variant}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

SellerProductEntryForm.defaultProps = {
  symbol: CURRENCY,
};

SellerProductEntryForm.propTypes = {
  symbol: PropTypes.string,
  setState: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  updateProductSettingClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default SellerProductEntryForm;
