/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Box, Card, IconButton, Button,
} from "@mui/material";
import {checkUserAllowed, getLocalDate, getLogo} from "helpers/utils";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewListIcon from '@mui/icons-material/ViewList';
import EditIcon from "@mui/icons-material/Edit";
import PercentIcon from '@mui/icons-material/Percent';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import "./AdminSellerShop.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H2, H3, H4, H5 } from "components/CustomTypography";
import {DATE_TIME_FORMAT_SHORT} from "config/constants";

const AdminSellerSubscription = (props) => {
  const [state, setState] = useState({
    open: false,
  });

  const history = useHistory();

  const {
      order = {}, editShopStatus, userDetails, editShopProfile
  } = props;

    const {
        shop = {}, seller = {}
    } = order;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

  const handleProductsButtonClick = (shop) => {
    history.push(`/products/shop/${shop.id}`);
  };

  const handleOrdersButtonClick = (shop) => {
    history.push(`/orders/shop/${shop.id}`);
  };

    const handleShopButtonClick = (shop) => {
        history.push(`/shops/shop/${shop.id}`);
    };

  return (
      <Box mb={2}>
          <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={editShopStatus /* () => handleShopButtonClick(shop) */}>
              <LazyImage
                  src={getLogo(shop.logo)}
                  width={150}
                  height={150}
                  alt={shop.title}
                  mx="auto"
              />
          </HoverBox>
        <H2 textTransform="uppercase" fontSize={12} textAlign="left" ellipsis>
            {order.order_id}
        </H2>
        <H4 textAlign="left">
            {(checkUserAllowed(userDetails.userlevel, "verifyshop") ||
                checkUserAllowed(userDetails.userlevel, "approveshop")) &&
                <IconButton color="primary" size="small" onClick={editShopStatus}>
                    <MoreHorizIcon/>
                </IconButton>
            }
            {(checkUserAllowed(userDetails.userlevel, "editshop")) &&
                <IconButton color="primary" size="small" onClick={editShopProfile}>
                    <EditIcon />
                </IconButton>
            }

        </H4>
          <H2 fontSize={10} textAlign="left" ellipsis>
              {order.shop && order.shop.title} {order.shop && " (" + order.shop.city + ")" }
          </H2>
        <H5 fontSize={12} textAlign="left" ellipsis>
            {order.paymentstatus}
        </H5>
          <H5 fontSize={10} textAlign="left">
              {order.status}
          </H5>
        <H5 fontSize={10} textAlign="left" ellipsis>
          {getLocalDate(order.created_at, DATE_TIME_FORMAT_SHORT)}
        </H5>
      </Box>
  );
};

export default AdminSellerSubscription;
